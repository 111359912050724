/*  */
:focus { outline: none; }
.principal {padding-top:0.5em;}
.oculto {display:none}
/* header */
.header {margin-top:1%;margin-bottom:1%;}
.header h1 {text-align: center;}
.header svg:nth-child(5) {color:#C60B1E}

/* footer */
.footer {margin-top:0.5em}
.acercade , .soluciones {margin-top:0;}

.soluciones {background-color:#C60B1E;color:#ffffff;font-size:1em;}

/* Colores letras*/
.bg-slate-400 {background-color:#5d5d5d}
.border-slate-400 {border-color: #5d5d5d;}
.bg-green-500 {background-color: #00ce4b}
.border-green-500 {border-color: #00ce4b;}
.bg-yellow-500 { background-color: #ffbe13;}
.border-yellow-500 {border-color: #ffbe13;}

/* Colores letras*/
.border-solid {border-radius: 25px;}

/* genericos  */
p.margen-inferior {margin-bottom: 1em}
.logo {max-height: 60px;}  
/* botones  */
.bg-indigo-600 {background-color:#C60B1E;color:#ffffff;}
.soluciones:hover {
    --tw-bg-opacity: 1;
    background-color: #C60B1Eb0;
}
/*.hover\:bg-indigo-200:hover {
    --tw-bg-opacity: 1;
    background-color: #007A33b0;
}*/

.hover\:bg-indigo-700:hover {
    --tw-bg-opacity: 1;
    background-color: #C60B1Eb0;
}

.focus\:ring-2:focus {--tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) #C60B1E;}

.modal-cabecera {display: flex;}

svg.cursor-pointer:hover {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
}
.alarga {display: flex;align-items: center;}

/* BOTON MODAL PUBLI */

.boton-publi-modal{background-color: #16A34A;color:#ffffff}
.boton-publi-modal:hover{background-color: #22c55e;}

.publisobreteclado{display:flex;min-width:300px;max-width:600px;width:98%;height:60px;margin:auto}

/* ADAPTA A MOVILES PEQUEÑOS */

@media screen and (max-height: 600px) {

    .celda {height: 44px; width: 44px;}
    
    .letter-container {font-size: 1.75rem;line-height: 2rem;}
    
    .header img {max-height: 40px;}  
    }
